// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  keyGMaps: 'AIzaSyAP9AJOcMC8Kr6SN96vC32Oa7RPcbcJjhM',
  apiUrl: 'https://be.test.profitbyeducato.com/dashboard',
  mapsApi: 'https://maps.googleapis.com/maps/api',
  dynamikLinkToapp: 'https://test.profitbyeducato.com/#/login',
  sapApikey: '3_jKczLr_MWSM2mRkEWjuYJPcwQzd-di8c1LnXYKS9aEGE6mrs5WueY0xeOT252Ez6',
  gigya : `https://cdns.eu1.gigya.com/js/gigya.js?apikey=3_jKczLr_MWSM2mRkEWjuYJPcwQzd-di8c1LnXYKS9aEGE6mrs5WueY0xeOT252Ez6&regSource=ProFit-byeDucato_FiatPRO`,
  privacyLink : '',
  privacyDisclaimerJson: 'profit-privacy-disclaimers_test.json',
  termsLink: '',
  idpBroker:{
    user: '59106b1e-89e5-43a4-99dc-380584038000',
    key: 'eN3kM5yK7lK3lO6fH6mS0jX0wW2gL6qA7wY4bQ2rK7jD7cG2tB',
    link: 'https://api02-emea-qa.fcagroup.com/emea/extra/IdPBroker/b2c/v1/users/'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
